import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, filter, first, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConversationService } from 'src/models/conversation/conversation.service';

import { SfBrowserUtilities } from 'src/services/sf-browser-utilities.service';
import { CometChatService } from 'src/services/third-party/comet-chat.service';
import { UtilitiesService } from 'src/services/utilities.service';
import { HireOverlayComponent } from '../../../customer/shared/hire-overlay/hire-overlay.component';
import { verticalList } from '../../../verticals/verticals-constants';
import { HeaderData, HeaderLink } from '../header-data';
import { HeaderServices } from '../header.service';

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate('0.5s ease-out', style({ opacity: '1', transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'translateY(0%)' }),
        animate('0.3s ease-out', style({ opacity: '0', transform: 'translateY(-5%)' })),
      ]),
    ])
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavbarComponent implements AfterViewInit {

  @Input('sidenav') sidenav: MatSidenav;
  @Input() headerData: HeaderData;
  @Input() currentUrl: string;
  @ViewChild(HireOverlayComponent) hireOverlayComponent: HireOverlayComponent

  userHasChats$: Observable<boolean>;
  isLoggedInToChat$: Observable<boolean>;

  showMenuToggle: boolean
  showNavLinks: boolean
  verticals = verticalList
  unreadMessageCount: number = 0;
  unReadMessageTip: string = '';

  constructor(private router: Router, public headerServices: HeaderServices,
    public location: Location, public utilities: UtilitiesService, private browserUtilties: SfBrowserUtilities,
    private cometChatService: CometChatService,
    private conversationService: ConversationService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcMobileBools(event.target.innerWidth)
  }

  calcMobileBools(windowWidth: number) {
    this.showMenuToggle = windowWidth <= this.headerData.showMenuToggleWidth;
    this.showNavLinks = windowWidth > this.headerData.hideNavLinksWidth;
  }

  ngOnInit() {
    if (this.browserUtilties.isBrowser) {
      this.isLoggedInToChat$ = this.cometChatService.getIsLoggedIn$()
      this.userHasChats$ = this.cometChatService.getUserHasChats$()
    }

  }

  ngAfterViewInit(): void {
    this.initCountOfUnreadMessagesListener();
    this.calcMobileBools(this.browserUtilties.getWindowWidth())
  }

  ngOnChanges(): void {
    this.calcMobileBools(this.browserUtilties.getWindowWidth())
  }

  logoClicked() {
    if (!this.headerServices.isSideNavSticky)
      this.sidenav.close();
    this.utilities.smoothScrollToTop();
  }

  singOut() {
    this.headerServices.signOut();
  }


  inSkilledBranch() {
    return this.location.path().indexOf('/skilled-people') == 0
  }

  inClientBranch() {
    return this.location.path().indexOf('/client') == 0
  }

  inLandingPage() {
    const currentPathWithoutParams = this.router.url.split('#')[0].split("?")[0];
    return currentPathWithoutParams === '/'
  }

  routeToLink(link: string) {
    this.router.navigateByUrl(link);
    return false;
  }

  chlidLinkChanged(link) {
    if (link != null)
      this.routeToLink(link)
  }

  isSelectedChildLink(link: HeaderLink) {
    return (this.router.url.indexOf(link.url) == 0);
  }

  toggleHireOverlay() {
    this.hireOverlayComponent.toggleHireOverlay()
  }


  initCountOfUnreadMessagesListener() {
    this.cometChatService.isInitialized$.pipe(
      filter(isInitialized => isInitialized),
      first(),
      switchMap(_ => this.cometChatService.getMsgCountObs()),
      tap((unreadMessageCount) => {
        this.unreadMessageCount = unreadMessageCount;
        if (unreadMessageCount == 1) {
          this.unReadMessageTip = "You have an unread message"
        } else {
          this.unReadMessageTip = "You have " + unreadMessageCount + " unread messages"
        }
      }),

      // pairwise(),
      // filter(([prevMessageCount, currMessageCount]) => {
      //   return (prevMessageCount != currMessageCount) && currMessageCount > 0
      // }),
      // map(([prev, curr]) => curr)
    )
      .subscribe();
  }
}
