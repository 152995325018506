<div class="left-col">
  <button mat-icon-button *ngIf="showMenuToggle" (click)="sidenav.toggle()" id='sideNavToggler'>
    <app-svg-icon width="20px" color="var(--font-color)" iconSrc='assets/icons/menu-icon.svg' alt="menu icon icon" />
  </button>
  <div id='toolbar-logo'>
    <a (click)='logoClicked()' routerLink="/">
      <img [src]="headerServices.headerTheme.logoSrc" width="200" alt="SupportFinity logo">
    </a>
  </div>
  <div class="user-links" *ngIf="showNavLinks">
    <app-header-unauthenticated-navbar *ngIf='!(headerServices.isLoggedIn$ | async)' [currentUrl]="currentUrl" />
    <app-header-authenticated-navbar *ngIf='(headerServices.isLoggedIn$ | async)' [headerData]="headerData"
      [currentUrl]="currentUrl" />
  </div>
</div>

<div class="right-col d-flex align-items-center margin-childs-horizontal-10">
  <app-contact-us-btn matTooltip="Contact us"
    *ngIf="!headerServices.isUserSkilled() && headerServices.headerTheme.contactUsImgSrc"
    [imgSrc]="headerServices.headerTheme.contactUsImgSrc" />

  <a *ngIf="headerServices.isUserCustomer()" class="header-button" href="https://help.supportfinity.com/en/"
    target="_blank" matTooltip="Help center">
    <app-svg-icon iconSrc="assets/icons/help-center.svg" />
  </a>

  <ng-container *ngIf="!(headerServices.isLoggedIn$ | async)">
    <div class='desktop-buttons d-none d-md-flex'>
      <a (click)='headerServices.openLogin()' href='login'>Login</a>
      <span class="d-flex align-items-center mg-r-12">|</span>
      <a class="mg-r-36" href='login' (click)='headerServices.openLogin()'>Sign Up</a>

      <ng-container *ngIf="!inSkilledBranch()">
        <a class="d-block" routerLink="/client/post-your-job">
          <button mat-button class='btn-bk-white'>
            Post Free Jobs
          </button>
        </a>
      </ng-container>
    </div>

    <a (click)='headerServices.openLogin()' href='login'>
      <button mat-button class="d-block d-md-none btn-bk-secondary"
        [ngClass]="{'btn-bk-secondary': !inLandingPage(), 'btn-bk-white':inLandingPage()}">
        <span [ngClass]="{'f-white': !inLandingPage(), 'f-secondary': inLandingPage()}">
          Sign Up
        </span>
      </button>
    </a>
  </ng-container>

  <!-- <app-job-vacancy-text-search class="d-none d-md-block" *ngIf="headerServices.isUserSkilled()"
    [clearAfterSearch]='true' [alwaysMini]="true">
  </app-job-vacancy-text-search> -->

  <ng-container *ngIf="(headerServices.isLoggedIn$ | async)">
    <a *ngIf='(userHasChats$| async) || (userLoggedInChat$| async) ; else noMessages' routerLink="/messages">
      <div class="header-button"
        [matTooltip]="unreadMessageCount>0?unReadMessageTip:(unreadMessageCount==0?'My Messages':null)">

        <app-svg-icon iconSrc="/assets/icons/message-icon.svg" height="18px" class="unread-count"
          [matBadgeHidden]='unreadMessageCount===0' matBadgeSize='small' [matBadge]='unreadMessageCount'
          [color]="unreadMessageCount===0?'var(--secondary)':'var(--deep-sky-blue)'" />
      </div>
    </a>

    <ng-template #noMessages>
      <div class="header-button" [matTooltip]="'You have no messages'">
        <app-svg-icon iconSrc="/assets/icons/message-icon.svg" color="var(--secondary)" height="18px" />
      </div>
    </ng-template>

    <app-header-feed-container matTooltip="My Notifications" *ngIf='!headerServices.isUserAdmin()' />

    <app-header-company-package-details *showOn="'md'" [openDetailsPopup]="true" />

    <app-header-profile-container style="margin-left:10px" />
  </ng-container>
</div>