<ng-container *isSkilled>
  <div class='profile-container' (click)='toggleOptionsList(profileContainer);' (focusout)="closeOptionsList()"
    tabindex="0" #profileContainer>
    <app-image-round class='profile-image' width="50px" [letter]='headerServices?.userInfo?.first_name[0]'
      [imageSrc]='headerServices.photoFileControl?.value?.downloadUrl'>
    </app-image-round>
    <div class='profile-info d-none d-md-block' style="margin:0 15px">
      <div class='profile-name'>
        {{headerServices.userInfo?(headerServices.userInfo.first_name+" "+headerServices.userInfo.last_name):''}}
      </div>
      <div class='profile-balance' *ngIf="headerServices.isUserSkilled()">
        {{headerServices.userInfo?(headerServices.userInfo.balance | currency )+' USD':''}}
      </div>
    </div>
    <app-svg-icon color="var(--secondary)" class='dropdown-profile-options d-none d-md-block' width="12px"
      alt="dropdown icon" iconSrc="assets/icons/dropdown.svg"/>
  </div>
</ng-container>

<ng-container *isCustomer>
  <div class="customer-img" [matTooltip]="headerServices?.userInfo?.fullName" (click)='toggleOptionsList(customerImg);'
    (focusout)="closeOptionsList()" tabindex="0" #customerImg>
    <app-image-round class='profile-image' width="50px" [letter]='headerServices?.userInfo?.first_name[0]'
      [imageSrc]='headerServices.photoFileControl?.value?.downloadUrl'>
    </app-image-round>
  </div>
</ng-container>

<ng-container *isAdmin>
  <div class="profile-container" (click)='toggleOptionsList(adminImg);' (focusout)="closeOptionsList()" tabindex="0"
    #adminImg>
    <app-image-round class='profile-image' width="50px" [letter]='headerServices?.userInfo?.first_name[0]'
      [imageSrc]='headerServices.photoFileControl?.value?.downloadUrl'>
    </app-image-round>
    <div class='profile-info d-none d-md-block' style="margin:0 15px">
      <div class='profile-name'>
        {{headerServices.userInfo?(headerServices.userInfo.first_name+" "+headerServices.userInfo.last_name):''}}
      </div>
    </div>
  </div>
</ng-container>



<ng-template #profileOptions>
  <div *ngIf="dropDownLinks?.length" class='profile-options' @fadeInOut>
    <ul>
      <a *ngFor="let link of dropDownLinks" [href]="link.url" (click)='routeToLink(link.url);$event.preventDefault();'
        (mousedown)='$event.preventDefault();'>
        {{link.name}}
      </a>
      <a (mousedown)='$event.preventDefault();' (click)='headerServices.signOut()' class="red">
        Logout
      </a>
    </ul>
  </div>
</ng-template>


<ng-template #companyOptions>
  <div class='profile-options' @fadeInOut>
    <ul>
      <a [href]="companyProfileLink" (click)='routeToLink(companyProfileLink);$event.preventDefault();'
        (mousedown)='$event.preventDefault();'>
        Company profile
      </a>
      <a (mousedown)='$event.preventDefault();' (click)='routeToLink(careersLink);$event.preventDefault();'>
        Careers page
      </a>
      <a (mousedown)='$event.preventDefault();' (click)='routeToLink(pipelinesLink);$event.preventDefault();'>
        Hiring pipelines
      </a>
    </ul>
  </div>
</ng-template>
