<mat-accordion class="nav-bar-expansion">
  <mat-expansion-panel class="no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <mat-panel-title class="expansion-panel-title">
        <a class="f-16" (click)="sidenav.toggle()" routerLink="/" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <div class="expansion-panel-title">Hire</div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel class="no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <mat-panel-title class="expansion-panel-title">
        <a class="f-16" (click)="sidenav.toggle()" routerLink="/skilled-people/find-work" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <div class="expansion-panel-title">Browse jobs</div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel class="no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <mat-panel-title class="expansion-panel-title">
        <a class="f-16" (click)="sidenav.toggle()" routerLink="/skilled-people" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <div class="expansion-panel-title">Join community</div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel class="no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <mat-panel-title class="expansion-panel-title">
        <a class="f-16" (click)="sidenav.toggle()" routerLink="/find-salaries" routerLinkActive="active">
          <div class="expansion-panel-title">Find Salaries</div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>

  <mat-expansion-panel class="no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <mat-panel-title class="expansion-panel-title">
        <a class="f-16" (click)="sidenav.toggle()" href="https://help.supportfinity.com" target="_blank">
          <div class="expansion-panel-title">Help</div>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <hr class="mg-auto mg-t-8 mg-b-8 f-grey">
  <mat-expansion-panel class="mat-expansion-parent no-indicator">
    <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
      <a class="f-16" href='login' (click)="sidenav.toggle();headerServices.openLogin()">
        Join or Login
      </a>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>

<div class="bottom-section-not-loggedin margin-childs-vertical-s">
  <a class="button-link-wrapper" (click)="sidenav.toggle()" routerLink="/client/post-your-job">
     <button mat-button class='btn-bk-deep-sky-blue'>
      Post Free Jobs
    </button>
  </a>
</div>
