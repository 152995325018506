import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { CompanyCardModule } from 'src/app/customer/company/company-card/company-card.module';
import { LabelWithStatusModule } from 'src/app/customer/customer-dashboard/shared/label-with-status/label-with-status.module';
import { ContactUsBtnModule } from 'src/app/shared/buttons/contact-us-btn/contact-us-btn.module';
import { AuthorizeDirectivesModule } from 'src/app/shared/directives/authorize/authorize-directives.module';
import { DelayedHoverModule } from 'src/app/shared/directives/delayed-hover/delayed-hover.module';
import { MobileDesktopFlipModule } from 'src/app/shared/directives/mobile-desktop-flip/mobile-desktop-flip.module';
import { NgVarModule } from 'src/app/shared/directives/ng-var/ng-var.module';
import { AiIconModule } from 'src/app/shared/icons/ai-icon/ai-icon.module';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';
import { ImageSquareModule } from 'src/app/shared/image/image-square/image-square.module';
import { JobVacancyTextSearchModule } from 'src/app/skilled-people/shared/job-vacancy-text-search/job-vacancy-text-search.module';
import { TeamDataService } from 'src/ngrx/entities/team/team-data.service';
import { MainPipeModule } from 'src/pipes/main.pipes.module';
import { UtilitiesService } from 'src/services/utilities.service';
import { CompanyPackageCardModule } from './../../customer/company/company-package-card/company-package-card.module';
import { ImageRoundModule } from './../../shared/image/image-round/image-round.module';
import { VerticalsNavigationSectionModule } from './../../verticals/shared/verticals-navigation-section/verticals-navigation-section.module';
import { HeaderCompanyPackageDetailsComponent } from './header-company-package-details/header-company-package-details.component';
import { HeaderFeedContainerComponent } from './header-feed-container/header-feed-container.component';
import { HeaderAuthenticatedNavbarComponent } from './header-navbar/header-authenticated-navbar/header-authenticated-navbar.component';
import { HeaderNavbarComponent } from './header-navbar/header-navbar.component';
import { HeaderUnauthenticatedNavbarComponent } from './header-navbar/header-unauthenticated-navbar/header-unauthenticated-navbar.component';
import { HeaderProfileContainerModule } from './header-profile-container/header-profile-container.module';
import { HeaderAdminSideNavComponent } from './header-side-nav/header-admin-side-nav/header-admin-side-nav.component';
import { HeaderAnonymousSideNavComponent } from './header-side-nav/header-anonymous-side-nav/header-anonymous-side-nav.component';
import { HeaderCustomerSideNavComponent } from './header-side-nav/header-customer-side-nav/header-customer-side-nav.component';
import { HeaderSideNavComponent } from './header-side-nav/header-side-nav.component';
import { HeaderSkilledSideNavComponent } from './header-side-nav/header-skilled-side-nav/header-skilled-side-nav.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNavbarComponent,
    HeaderUnauthenticatedNavbarComponent,
    HeaderSideNavComponent,
    HeaderCustomerSideNavComponent,
    HeaderAdminSideNavComponent,
    HeaderAnonymousSideNavComponent,
    HeaderSkilledSideNavComponent,
    HeaderFeedContainerComponent,
    HeaderAuthenticatedNavbarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatBadgeModule,
    MatSidenavModule,
    MatButtonModule,
    MatProgressBarModule,
    VerticalsNavigationSectionModule,
    MatExpansionModule,
    ImageRoundModule,
    ImageSquareModule,
    RouterModule,
    SvgIconModule,
    LabelWithStatusModule,
    MainPipeModule,
    DelayedHoverModule,
    MatSelectModule,
    NgPipesModule,
    JobVacancyTextSearchModule,
    MatMenuModule,
    ContactUsBtnModule,
    MatTooltipModule,
    CompanyPackageCardModule,
    AuthorizeDirectivesModule,
    MobileDesktopFlipModule,
    HeaderProfileContainerModule,
    NgVarModule,
    CompanyPackageCardModule,
    CompanyCardModule,
    AiIconModule,
    HeaderCompanyPackageDetailsComponent
  ],
  exports: [
    HeaderComponent
  ],
  providers: [TeamDataService, UtilitiesService]

})
export class HeaderModule {}


