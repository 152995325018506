import { CommonModule, Location } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaLoaderService, RecaptchaSettings } from 'ng-recaptcha';
import { SnackBarComponent } from 'src/app/shared/popups/snack-bar/snack-bar.component';
import { TransferStateInterceptor } from 'src/interceptors/transfer-state.interceptor';
import { AppUserNotificationServices } from 'src/models/appUserNotification/app-user-notification.service';
import { AlertFactoryService } from 'src/services/alertFactory.service';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { LinkedinAuth } from 'src/services/authentication/linkedin.auth.service';
import { ConnectionService } from 'src/services/connection.service';
import { DialogLazyOpenService } from 'src/services/dialog-open.services';
import { SfBrowserUtilities } from 'src/services/sf-browser-utilities.service';
import { GoogleAnalyticsService } from 'src/services/third-party/google-analytics.service';
import { UtilitiesService } from 'src/services/utilities.service';
import { environment } from '../environments/environment';
import { AppStoreModule } from '../ngrx/store/app-store.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './core/header/header.module';
import { MaterialAppModule } from './ngmaterial.module';
import { CircleButtonModule } from './shared/icons/circle-button/circle-button.module';
import { ExitIconModule } from './shared/icons/exit-icon/exit-icon.module';
import { SvgIconModule } from './shared/icons/svg-icon/svg-icon.module';
import { UserImageModule } from './shared/input-fields/file-upload/user-image/user-image.module';
import { EditPopupModule } from './shared/input-fields/rename-popup/edit-popup.module';
import { LoadingModule } from './shared/loadings/loading/loading.module';
import { LottieLoadingModule } from './shared/loadings/lottie-loading/lottie-loading.module';
import { AlertComponent } from './shared/popups/alert/alert.component';
import { RecaptchaModule } from './shared/security/recaptcha/recaptcha.module';
import { SFSocialLoginModule } from './shared/security/sf-social-login.module';
import { XsrfInterceptor } from 'src/interceptors/XSRFInterciptor';

@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        SnackBarComponent,
    ],
    exports: [
        AppComponent,
        AlertComponent,
        SnackBarComponent,
        CommonModule,
        LoadingModule,
        MatDialogModule,
        MatSnackBarModule,
        MaterialAppModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        EditPopupModule,
        MatToolbarModule,
        MatListModule,
        SFSocialLoginModule,
        MatSidenavModule,
        CircleButtonModule,
        UserImageModule,
        MatProgressBarModule,
        AppStoreModule,
        MatTooltipModule,
        SvgIconModule,
        RecaptchaModule,
        LottieLoadingModule,
        RouterModule,
        // FirebaseAppModule,
        // FirestoreModule,
        HeaderModule,
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LoadingModule,
        MatDialogModule,
        MatSnackBarModule,
        MaterialAppModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        EditPopupModule,
        MatToolbarModule,
        MatListModule,
        SFSocialLoginModule,
        MatSidenavModule,
        CircleButtonModule,
        UserImageModule,
        MatProgressBarModule,
        HeaderModule,
        AppStoreModule,
        MatTooltipModule,
        RouterModule.forRoot([]),
        SvgIconModule,
        RecaptchaModule,
        LottieLoadingModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideStorage(() => getStorage()),
        ExitIconModule], providers: [
            provideClientHydration(withNoHttpTransferCache()),
            // { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
            LinkedinAuth, AuthenticationService, ConnectionService, SfBrowserUtilities,
            { provide: HTTP_INTERCEPTORS, useClass: TransferStateInterceptor, multi: true },
            {
                provide: RECAPTCHA_SETTINGS,
                useValue: { siteKey: '6LcWHMoUAAAAACbhUACc_io2dBhSq5SMZIYStjyy' } as RecaptchaSettings,
            },
            AlertFactoryService,
            UtilitiesService,
            GoogleAnalyticsService,
            AppUserNotificationServices,
            DialogLazyOpenService,
            Location,
            RecaptchaLoaderService,
            provideHttpClient(withInterceptorsFromDi(), withFetch()),
        ]
})
export class AppSharedModule { }
